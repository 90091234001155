<template>
  <div class="content">
    <table>
      <tr>
        <td v-for="item in tdList" :key="item.value">{{ item.label }}</td>
      </tr>
      <tr v-for="(item, index) in tableData" :key="index">
        <td>{{ item.label }}</td>
        <td>{{ item.value }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "TabelModule",
  components: {},
  data() {
    return {}
  },
  props: {
    // 表头信息
    tdList: {
      type: Array,
      default: []
    },
    // 列表数据
    tableData: {
      type: Array,
      default: []
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin-right: 20px;

  table {
    border-collapse: collapse;
    font-size: 14px;

    tr:nth-child(1) {
      td {
        background: #f5f5f5;
        font-weight: bold;
      }
    }
    td {
      width: 270px;
      height: 36px;
      opacity: 1;
      background: #fff;
      color: #333333;
      border: 1px solid #ccc;
      text-align: center;
    }
  }
}
</style>
